import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { startComment, endComment } from '../../data/specialText';
import { BottomTextContainer } from '../../aesthetic/styledComponents';
import { orgLinks } from '../../data/orgLinks';

const BottomText = () => {
  return (
    <BottomTextContainer isMobile={isMobile} isTablet={isTablet}>
      <p>
        <span className="comment">{startComment}</span> <b>Thanks for looking! While you're here, please consider donating to some of my favorite organizations!</b> <span className="comment">{endComment}</span>
      </p>

      <div>
        {orgLinks.map((link, index) => (
          <React.Fragment key={index}>
            <a href={link.link} target="_blank" rel="noopener noreferrer">{link.linkText}</a>
            {index !== orgLinks.length - 1 && <>&nbsp;&nbsp;/&nbsp;&nbsp;</>}
          </React.Fragment>
        ))}
      </div>
    </BottomTextContainer>
  );
}

export default BottomText;