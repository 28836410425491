export const pastProjects = [
  {
		header: `LIPS.SOCIAL`,
		content: `A feminist led social media platform designed for women, queer, and sex worker identified individuals seeking space to express themselves without the unhealthy aspects of mainstream internet culture.`,
		date: `October 2021 — June 2022`,
		linkText: `launch application`,
		link: `https://lips.social`
	},
  {
		header: `BODY OF WORKERS`,
		content: `A virtual art gallery where artists and documentarians in sex industries can share work and strengthen the community. Built by queer, trans/nb, BIPOC, and sex working people using the PERN-stack with Redux and Hooks.`,
		date: `May — December 2021`,
		linkText: `ask me about it`,
		link: `http://linkedin.com/in/baites`,
		selected: true
	},
  {
    header: `MY GEY FRIEND`,
    content: `'My Generic Egg Yolk Friend' (AKA 'My Gey Friend') is a take on the classic Tamagotchi keychain. Featuring a "device" with animated screen built with HTML, CSS, vanilla JavaScript, and custom made pixel graphics. — Warning: Pet may be susceptible to depression and getting stuck in bed.`,
    date: `October 2021`,
    linkText: `play game`,
    link: `https://h-b8.github.io/tamagotchi`
  },
  {
    header: `CONVERT + CONNECT`,
    content: `A browser application that takes the compressed json file that comes with an Instagram data download, and renders it in a readable user interface, including a display of followers, following, and mutuals. Built with React.js, Hooks, and CSS.`,
    date: `December 2020`,
    linkText: `launch application`,
    link: `https://h-b8.github.io/connections-data-converter`,
    selected: true
  },
  {
    header: `HAVENMEDIA.ORG`,
    content: `A social space for BIPOC women working towards abolition, liberation, and healing through art and storytelling. Built using Duda with custom HTML, CSS, and Vanilla JavaScript.`,
    date: `June 2020 — February 2021`,
    linkText: `visit website`,
    link: `https://www.havenmedia.org`
  },
  {
    header: `RECOVERY`,
    content: `A medical, health, and wellness browser application built with Python, Django, Vanilla JavaScript, CSS, Bootstrap, and Chart.js. Group project, deployed to Heroku.`,
    date: `March 2020`,
    linkText: `view repository`,
    link: `https://github.com/H-b8/recovery`
  },
  {
    header: `SL*T MACHINE`,
    content: `A cam-culture themed slot machine game. Built with a minimal aesthetic, using HTML, CSS, and Vanilla Javascript. Featuring blackwork flash tattoo styled illustrations by me! Deployed to GitHub Pages.`,
    date: `February 2020`,
    linkText: `play game`,
    link: `https://h-b8.github.io/slot-machine`,
    selected: true
  },
  {
    header: `TIC/TAC/TOE`,
    content: `A minimal aesthetic two-player game built with HTML, CSS, and Vanilla JavaScript. Deployed to GitHUb Pages.`,
    date: `February 2020`,
    linkText: `play game`,
    link: `https://h-b8.github.io/tic-tac-toe`
  },
  {
    header: `ROYALEPROJECTS.COM`,
    content: `A dynamic art gallery portfolio and content management website. Built with Squarespace, following client's design and creative direction.`,
    date: `January 2018`,
    linkText: `view website`,
    link: `https://www.royaleprojects.com/`
  },
];