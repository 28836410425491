export const skillsets = [
  {
    header: 'PROJECT PLANNING',
    tools: ['Monday', 'GitHub', 'Jira', 'Notion', 'Trello']
  },
  {
    header: 'WIREFRAMING AND PROTOTYPING',
    tools: ['Adobe (Xd, InDesign)', 'Figma']
  },
  {
    header: 'PROGRAMMING LANGUAGES',
    tools: ['C++', 'Java', 'JavaScript', 'Python', 'TypeScript']
  },
  {
    header: 'LIBRARIES & FRAMEWORKS',
    tools: ['Django', 'Express', 'Next.Js', 'React.Js', 'React Native']
  },
  {
    header: 'DATABASE MANAGEMENT',
    tools: ['AWS (S3)', 'MongoDB Atlas', 'PostgreSQL', 'Prisma']
  },
  {
    header: 'DESIGN EXECUTION',
    tools: ['CSS', 'EJS', 'HTML', 'JSX', 'Sass', 'Styled Components']
  },
  {
    header: 'WEBSITE BUILDING',
    tools: ['Duda', 'Squarespace', 'Tumblr', 'WebFlow', 'Wix (Velo)', 'Wordpress']
  },
];