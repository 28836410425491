import React from 'react';
import { ImageContainer, ParallaxContainer } from '../../aesthetic/styledComponents';
import Primary from '../../aesthetic/images/primary.png';
import Secondary from '../../aesthetic/images/secondary.png';

const BackgroundImagery = ({ scrollPosition }) => {
	return (
		<ImageContainer>
			<ParallaxContainer main parallax={scrollPosition / 20}>
				<img src={Primary} alt="primary.png" />
			</ParallaxContainer>
			<ParallaxContainer parallax={scrollPosition / -13}>
				<img src={Secondary} alt="secondary.png" />
			</ParallaxContainer>
		</ImageContainer>
	);
}

export default BackgroundImagery;