export const currentEndeavors = [
	{
		header: `CHANI APP`,
		content: `Combining the wisdom of the stars with mindfulness and meditation, CHANI supports your growth and well-being by providing the most detailed birth chart and transit explanations available.`,
		date: `March 2022 — Present`,
		linkText: `launch application`,
		link: `https://chaninicholas.com/chani-app/`
	},
	{
		header: `HELL'S BOUNDARIES`,
		content: `Conceptual art in the form of a booking request app. Born from the anxiety I hold around saying "no" and being rejected, both of which hinder me from spending time with the people I love. Built using the MERN-stack, Mailgun API, and Styled Components.`,
		date: `August 2021 — Present`,
		linkText: `launch application`,
		link: `https://hells-boundaries.vercel.app/`,
		selected: true
	},
	{
		header: `GOES AGAINST`,
		content: `A "link-in-bio"-esque application aimed toward hosting those whose mere existence "goes against" popular social media's community guidelines. Originally built using the MEN-stack with OAuth, EJS, and CSS for a school project. Currently being refactored to MERN-stack`,
		date: `March 2020 — Present`,
		linkText: `view repository`,
		link: `https://github.com/H-b8/goes-against`,
		selected: true
	},
	{
		header: `UNNAMED`,
		content: `A content sharing and paid subscription platform for artists and sex workers. Built using the PENN-stack with Styled Components. This goal of this project is to provide beautiful uncensored feeds, and move towards allowing our users to accept cryptocurrencies.`,
		date: `July 2020 — Present`,
		linkText: `ask me about it`,
		link: `http://linkedin.com/in/baites`
	},
  {
		header: `[ REDACTED ].COM`,
		content: `A dynamic art portfolio website and online store, featuring my personal work. Previously built with Wix/Velo, and currently being remade with Next.js. Constantly growing and changing in terms of content and functionality.`,
		date: `2018 — Present`,
		linkText: `if you know, you know`,
		link: ``,
	},
  {
    header: `BAITES.DEV`,
    content: `This static, one-paged, little baby features a fully responsive && playful design (by me) to showcase personality and frontend development skills. Varying presentations for desktop, tablet, and mobile. Built with React.js, Hooks, Styled Components, and pure CSS animations.`,
    date: `October 2020`,
    linkText: `you're already here`,
    link: ``
  },
];