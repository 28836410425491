import React, { useState } from 'react';
import { isTablet } from 'react-device-detect';
import { pastProjects } from '../../data/pastProjects';
import { currentEndeavors } from '../../data/currentEndeavors';
import { openingBrace, closingBrace, startComment, endComment } from '../../data/specialText';
import { CardsContainer, Card, Expander, RowContainer } from '../../aesthetic/styledComponents';
import GA from 'react-ga';

const Projects = ({ isPast, isCurrent }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  let projectsHeader;
  let projectsData = [];

  if (isPast) {
    projectsHeader = 'pastProjects';
    projectsData = pastProjects;
  } else if (isCurrent) {
    projectsHeader = 'currentEndeavors';
    projectsData = currentEndeavors;
  }

  return (
    <div>
      <h2>let {projectsHeader} = {openingBrace}</h2>

      <CardsContainer isExpanded={isExpanded} isTablet={isTablet}>
        {projectsData.map((project) => {
          return (
            <Card isTablet={isTablet}>
              <h3>{project.header}</h3>
              <p>{project.content} ({project.date})</p>
              <GA.OutboundLink eventLabel={project.header} to={project.link} target="_blank" style={{ pointerEvents: project.link === '' ? 'none' : '' }}>
                {project.linkText}
              </GA.OutboundLink>
            </Card>
          );
        })}
      </CardsContainer>

      <RowContainer>
        <h2>{closingBrace};</h2>
        <Expander onClick={() => setIsExpanded(!isExpanded)}>
          &nbsp;&nbsp;&nbsp;<span className="comment">{startComment}</span> VIEW {!isExpanded ? 'MORE' : 'LESS'} <span className="comment">{endComment}</span>
        </Expander>
      </RowContainer>
    </div>
  );
}

export default Projects;