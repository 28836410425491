import React from 'react';
import { closingBrace, openingBrace } from '../../data/specialText';
import { ColorBlockContainer, HeaderText, Button, TypeAnimationContainer } from '../../aesthetic/styledComponents';

const ColorBlock = ({ scrollPosition, scrollToPastProjects }) => {
  return (
    <ColorBlockContainer>
      <HeaderText parallax={scrollPosition / (-1.3)}>
        <h1>HELLO WORLD!</h1>
        <TypeAnimationContainer>
          <p>import {openingBrace} CarolineBaites {closingBrace} from 'Los-Angeles-CA';</p>
          <p>&nbsp;</p>
          <p>let myDescriptors = [ 'Artist', 'Engineer', 'Astrological Mathematician' ];</p>
          <p className="pronouns">let myPronouns = [ 'She', 'Her' ];</p>
        </TypeAnimationContainer>
        <Button onClick={() => scrollToPastProjects()}>GIT TO THE GOOD STUFF</Button>
      </HeaderText>
    </ColorBlockContainer>
  );
}

export default ColorBlock;