import React, { useEffect } from 'react';
import GA from 'react-ga';
import Bio from '../browser/components/Bio';
import BottomText from '../browser/components/BottomText';
import { currentEndeavors } from '../data/currentEndeavors';
import { pastProjects } from '../data/pastProjects';
// import { resumeLink } from '../data/documentLinks';
import { skillsets } from '../data/skillsetData';
import { closingBrace, endComment, openingBrace, slashComment, startComment } from '../data/specialText';
import Primary from '../aesthetic/images/primary.png';
import './mobile.css';

const Mobile = () => {
  let selectedProjects = [];
  pastProjects.map(pp => pp.selected && selectedProjects.push(pp));
  currentEndeavors.map(ce => ce.selected && selectedProjects.push(ce));

  useEffect(() => {
    GA.initialize('UA-204784335-1');
    GA.pageview(window.location.pathname);
  }, []);

  return (
    <body>
      <img src={Primary} alt="primary.png" />

      <div class="links-container">
        {/* <GA.OutboundLink eventLabel="resume mobile" to={resumeLink} target="_blank">
          <p>RESUME <span>⟶</span></p>
        </GA.OutboundLink> */}

        <GA.OutboundLink eventLabel="linkedin mobile" to="https://www.linkedin.com/in/baites/" target="_blank">
          LINKEDIN <span>⟶</span>
        </GA.OutboundLink>

        <GA.OutboundLink eventLabel="github" to="http://github.com/H-b8" target="_blank">
          GITHUB <span>⟶</span>
        </GA.OutboundLink>
      </div>

      <div class="skillset-container">
        <h2>{slashComment} HELLO WORLD!</h2>
        <div class="initializations">
          <p>import {openingBrace} <b>CarolineBaites</b> {closingBrace} <b>'Los-Angeles-CA'</b>;</p>
          <p>
            let <b>myDescriptors</b> = [ 'Artist', 'Engineer', 'Astrological Mathematician' ];
            <br />
            let <b>myPronouns</b> = [ 'She', 'Her' ];
          </p>
        </div>

        <h2>let mySkillset = {openingBrace}</h2>
        {skillsets.map((skillset, idx) => {
          return (
            <div class="skillset-item">
              <p class="skillset-header">{skillset.header}</p>
              <p>
                {skillset.tools.map(tool => {
                  return (
                    <>{tool} / </>
                  );
                })}
              </p>
              {idx !== skillsets.length - 1 && <br />}
            </div>
          );
        })}
        <h2>{closingBrace};</h2>
      </div>

      <Bio />

      <div class="projects-container">
        <h2>let selectedProjects = {openingBrace}</h2>
        {selectedProjects.map((project, idx) => {
          return (
            <>
              {project.selected &&
                <a href={project.link} target="_blank" rel="noopener noreferrer" isMobile class="project-item">
                  '{project.header}'{idx !== selectedProjects.length - 1 && <span>,<br /></span>}
                </a>
              }
            </>
          );
        })}
        <h2>{closingBrace};</h2>
        <p class="projects-subtext">
          {startComment} A full list of current/past projects + more in-depth details can be viewed via desktop browser {endComment}
        </p>
      </div>

      <BottomText />
    </body>
  );
}

export default Mobile;