import React from 'react';
import { NavContainer } from '../../aesthetic/styledComponents';

const Navigation = ({ scrollToSkillset, scrollToPastProjects, scrollToCurrentEndeavors, scrollPosition, viewHeight, viewWidth }) => {
  return (
    <NavContainer scrollPosition={scrollPosition} viewHeight={viewHeight} viewWidth={viewWidth}>
      <p>
        const navigation = [ &nbsp;
        <span onClick={scrollToSkillset}>mySkillset</span>,&nbsp;
        <span onClick={scrollToPastProjects}>pastProjects</span>,&nbsp;
        <span onClick={scrollToCurrentEndeavors}>currentEndeavors</span>
        &nbsp; ];
      </p>
    </NavContainer>
  );
}

export default Navigation;