export const orgLinks = [
  {
    linkText: `BLACK TRANS FEMMES IN THE ARTS`,
    link: `https://www.artsbusinesscollaborative.org/asp-products/black-trans-femmes-in-the-arts-sponsored-projected_/`
  },
  {
    linkText: `BLACK TRANS LIVES THRIVE`,
    link: `https://www.grapevine.org/giving-circle/PdWhoa/Black-Trans-Lives-Thrive`
  },
  {
    linkText: `BLACK TRANS TRAVEL FUND`,
    link: `https://www.blacktranstravelfund.com/donate`
  },
  {
    linkText: `G.L.I.T.S. INC`,
    link: `https://www.glitsinc.org/donations`
  },
  {
    linkText: `INTL PLANNED PARENTHOOD FEDERATION`,
    link: `https://secure.ippf.org/a/donate-monthly`
  },
  {
    linkText: `LYSISTRATA MCCF`,
    link: `https://www.lysistratamccf.org/emergencyfund`
  },
  {
    linkText: `PAL HUMANITY`,
    link: `https://www.gofundme.com/f/pal-humanity-initiative`
  },
  {
    linkText: `Palestine Children's Relief Fund`,
    link: `https://pcrf1.app.neoncrm.com/forms/general`
  },
  {
    linkText: `RED CANARY SONG`,
    link: `https://www.redcanarysong.net/`
  },
  {
    linkText: `SWOP LOS ANGELES`,
    link: `https://swoplosangeles.org/donate-now`
  },
  {
    linkText: `TRANS LIFELINE`,
    link: `https://www.giveoutday.org/organization/Translifeline`
  },
];