import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { startComment, endComment } from '../../data/specialText';
import { BioContainer } from '../../aesthetic/styledComponents';

const Bio = () => {
  return (
    <BioContainer isMobile={isMobile} isTablet={isTablet}>
      <p>
        <span className="comment">{startComment}</span> Studio arts major, computer science quitter, software engineering immersive bootcamp grad, and aspiring devops girlie.
      </p>

      <p>
        Currently, I am working as a lead full-stack developer at CHANI, where I get to create the mathematical logic responsible for turning astronomical data into astrological birth charts and transit information.
      </p>

      <p>
        But in my free time, I enjoy creating my own applications, as well as volunteering my skills to organizations benefiting sex worker, LGBTQ+, BIPOC, and neurodivergent communities. When I'm not coding, I'm sporadically rotating between creating audio/video art, drawing, collaging, knitting, sewing, hand-spinning yarn, screenprinting, leatherworking, playing bass, and making memes about my feelings.
      </p>

      <p>
        Art and community are the most important things in my life. I find great joy in meeting like-minded people and applying my knowledge to building supportive spaces for marginalized people to thrive without fear of judgement, censorship, or deplatforming.
        If you or your organization share similar ideologies, I would very much love to meet you! Please reach out via <a href='http://linkedin.com/in/baites/'>LinkedIn</a>! <span className="comment">{endComment}</span>
      </p>
    </BioContainer>
  );
}

export default Bio;