import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import MobileView from './mobile/MobileView';
import BrowserView from './browser/BrowserView';

const Portfolio = () => {
  document.getElementById('title').innerHTML = `<CarolineBaites />`;
  return (
    <>
      <Helmet>
        <title id="title" />
        <meta name="description" content="Caroline Baites (She/Her) | Artist, designer, and full stack dev from Los Angeles, CA." />
        <meta name="keywords" content="portfolio, developer, art, artist, creative, frontend, fullstack, web design, web development, javascript, css, react, styled components, social justice, lgbtq, queer, bipoc, poc, pro sex, pro slut" />
        <meta property="og:image" content="aesthetic/images/primary.png" />
        <meta name="theme-color" content="#FFFFFF" media="(prefers-color-scheme: light)" />
      </Helmet>

      {isMobile && !isTablet
        ? <MobileView />
        : <BrowserView />
      }
    </>
  );
};

export default Portfolio;