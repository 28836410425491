import React from 'react';
import { openingBrace, closingBrace } from '../../data/specialText';
import { SkillsContainer, SkillsParallax } from '../../aesthetic/styledComponents';
import { skillsets } from '../../data/skillsetData';

const Skillsets = ({ scrollPosition }) => {
  return (
    <SkillsContainer>
      <SkillsParallax parallax={scrollPosition / (-1.10)}>
        <h2>let mySkillset = {openingBrace}</h2>
        {skillsets.map(skillset => {
          return (
            <div>
              <p><b>{skillset.header}</b></p>
              <p>{skillset.tools.map(tool => { return `${tool} / ` })}</p>
            </div>
          );
        })}
        <h2>{closingBrace};</h2>
      </SkillsParallax>
    </SkillsContainer>
  );
}

export default Skillsets;