/* ---------------------------------------- PROPERTY HIERARCHY ---------------------------------------- */

/*
		Container: outside to inside (position, margin, size, border, padding, background, parental behavior)
		Non-Container Children: alphabetical
		Interactive: (cursor) alphabetical
*/

/* ---------------------------------------- IMPORTS ---------------------------------------- */

import styled, { keyframes } from 'styled-components';

/* ---------------------------------------- PAGE ---------------------------------------- */

export const Page = styled.div`
	display: flex;
	flex-direction: column;
	font-family: 'Questrial Regular', sans-serif;
	cursor: default;

	& a, p {
		font-size: 0.80rem;
		letter-spacing: 2px;
		line-height: 1.3rem;
		text-transform: uppercase;
	}

	& a {
		color: var(--black);
		text-decoration: none;
	}

	& h2 {
		margin: 30px 0;
		font-size: 1.3rem;
	}

	& .comment {
		font-size: 1rem;
	}
`

export const SideText = styled.div`
	min-width: 7vh;

	& > p {
		position: fixed;
		top: ${props => props.isLeft ? '50vh' : '25vh'};
		left: ${props => props.isLeft ? '3.5vh' : ''};
		right: ${props => props.isRight ? '3.5vh' : ''};
		width: max-content;
		transform: ${props => props.isLeft ? 'translate(-50%, -50%) rotate(-90deg)' : 'translate(50%, 50%) rotate(90deg)'};

		& > a {
			border-bottom: 1px solid var(--black);
			padding: 3px 0;
			transition: 1s ease-in-out;
		}

		& > a:hover {
			border-bottom: 1px solid white;
			cursor: pointer;
		}
	}
`

export const Content = styled.div`
	margin: 0;
	width: calc(100vw - 14vh);
	display: flex;
	flex-direction: column;
	justify-content: center;
`

export const RowContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
`

export const ImageContainer = styled.div``

export const ParallaxContainer = styled.div.attrs(
	({ parallax }) => ({
		style: {
			top: parallax + 'px'
		}
	})
)`
	position: fixed;
	right: ${props => props.main ? '15%' : '5%'};
	z-index: ${props => props.main ? '-1' : '-2'};
	padding: 7vh;

	& > img {
		width: 25vw;
	}
`

/* ---------------------------------------- COLOR BLOCK ---------------------------------------- */

const DropAnimation = keyframes`
	0% {
		opacity: 0;
		transform: translateY(-50vh);
	}
	100% {
		opacity: 1;
		transform: translateY(0vh);
	}
`

const FadeAnimation = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`

const typeCursor = keyframes`
	0% {
		opacity: 1;
		width: 0;
	}
	99% {
		border-right: 0.15em solid white;
	}
	100% {
		border: none;
		opacity: 1;
	}
`

export const ColorBlockContainer = styled.div`
	display: flex;
	justify-content: center;
	height: ${props => props.isTablet ? '100vh' : '93vh'};
	background: var(--accent);
	@media (max-width: 720px) {
		padding: 0 50px;
	}
`

export const HeaderText = styled.div.attrs(
	({ parallax }) => ({
		style: {
			top: parallax + 'px'
		}
	})
)`
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 28vh;

	& > h1 {
		margin: 20px -9px;
		font-family: Arial-Black, Arial, sans-serif;
		font-size: 9vw;
		font-weight: 900;
		line-height: 9vw;
		opacity: 0;
		animation-name: ${DropAnimation};
		animation-duration: 2s;
		animation-fill-mode: forwards;
	}
`

export const TypeAnimationContainer = styled.div`
	& > p {
		overflow: hidden;
		border-right: 0.15em solid white;
		width: 100%;
		font-size: 2vw;
		font-weight: 400;
		letter-spacing: normal;
		line-height: 2.4vw;
		text-transform: none;
		white-space: nowrap;
		word-spacing: 0.2em;
	}

	& > .pronouns {
		margin-top: 1vw;
	}

	& > p:nth-child(1) {
		opacity: 0;
		width: 23em;
		-webkit-animation: ${typeCursor} 2s steps(40, end);
		animation: ${typeCursor} 2s steps(40, end);
		-webkit-animation-delay: 3s;
		animation-delay: 3s;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}

  // line skip
	& > p:nth-child(2) {
		opacity: 0;
		width: 0.05em;
		-webkit-animation: ${typeCursor} 0.2s steps(1, end);
		animation: ${typeCursor} 0.2s steps(1, end);
		-webkit-animation-delay: 5s;
		animation-delay: 5s;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}

	& > p:nth-child(3) {
		opacity: 0;
		width: 34em;
		-webkit-animation: ${typeCursor} 2s steps(40, end);
		animation: ${typeCursor} 2s steps(40, end);
		-webkit-animation-delay: 5.2s;
		animation-delay: 5.2s;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}

	& > p:nth-child(4) {
		opacity: 0;
		width: 16.5em;
		-webkit-animation: ${typeCursor} 1s steps(10, end);
		animation: ${typeCursor} 1s steps(10, end);
		-webkit-animation-delay: 7.5s;
		animation-delay: 7.5s;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}
`

export const Button = styled.button`
	margin: 30px 0;
	outline: none;
	border: 3px solid var(--black);
	height: 50px;
	width: 300px;
	padding: 0 20px;
	background: transparent;
	color: var(--black);
	font-family: Arial-Black, Arial, sans-serif;
	font-size: 1rem;
	opacity: 0;
	animation-delay: 9s;
	animation-name: ${FadeAnimation};
	animation-duration: 1.5s;
	animation-fill-mode: forwards;
	transition: 1.5s ease-in-out;

	&:hover {
		background: var(--black);
		color: var(--accent);
		cursor: pointer;
	}
`

/* ---------------------------------------- NAVIGATION ---------------------------------------- */

export const NavContainer = styled.div.attrs(
	({ scrollPosition, viewHeight, viewWidth }) => ({
		style: {
			position: (scrollPosition < (viewHeight * 0.93))
				? ''
				: (scrollPosition < (viewHeight * 1.95))
					? 'fixed'
					: 'absolute',
			top: (scrollPosition < (viewHeight * 1.95))
				? '0'
				: '195vh',
			right: (scrollPosition < (viewHeight * 0.93))
				? '0'
				: '7vh',
			transform: (scrollPosition < (viewHeight * 0.93) && viewWidth <= 1440)
				? `translate(${(scrollPosition) / 11.9}%, 0)`
				: (scrollPosition < (viewHeight * 0.93) && viewWidth <= 1920)
					? `translate(${(scrollPosition) / 12.6}%, 0)`
					: ''
		}
	})
)`
	height: 7vh;
	display: flex;
	align-items: center;
	word-spacing: 0.2em;
	transition: transform 0s ease-in-out, right 2s ease-in-out;

	& > p {
		font-size: 1rem;
		letter-spacing: normal;
		text-transform: none;
		background: ${props => props.scrollPosition < (props.viewHeight * 0.93) ? '' : 'var(--black)'};
		color: ${props => props.scrollPosition < (props.viewHeight * 0.93) ? '' : 'white'};
		padding: ${props => props.scrollPosition < (props.viewHeight * 0.93) ? '' : '0 30px'};
		transition: all 1.5s ease-in-out;

		& > span:hover {
			color: var(--accent);
			cursor: pointer;
			transition: color 1s ease-in-out;
		}
	}

	@media (max-width: 720px) {
		display: none;
	}
`

/* ---------------------------------------- SKILLSET ---------------------------------------- */

export const SkillsContainer = styled.div`
	height: 110vh;
`

export const SkillsParallax = styled.div.attrs(
	({ parallax }) => ({
		style: {
			top: parallax + 'px'
		}
	})
)`
	position: fixed;
	margin-top: 95vh;
	height: 100vh;
	padding: 0 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	& > h2 {
		color: ${props => props.isWhite ? 'white' : ''};
	}

	& > div {
		margin-left: 30px;

		& > p {
			margin: 0.5rem 0;
			color: ${props => props.isWhite ? 'white' : ''};
		}
	}

`

/* ---------------------------------------- BIOGRAPHY ---------------------------------------- */

export const BioContainer = styled.div`
	padding: ${props => props.isMobile && !props.isTablet ? '3vh' : '7vh 0'};
	background: white;

	& > p {
		padding-bottom: 1.6rem;
		font-size: 0.80rem;
		line-height: 1.5rem;
		text-align: justify;
	}
`

/* ---------------------------------------- PROJECTS ---------------------------------------- */

export const ProjectsContainer = styled.div`
	border-right: 3px solid var(--black);
	border-bottom: 3px solid var(--black);
	padding: 0 1.5vw 1.3vw 50px;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

export const CardsContainer = styled.div`
	max-height: ${props => props.isExpanded ? '4000px' : '425px'};
	max-width: calc(1000px + 4vw);
	padding: 0 50px;
	display: grid;
	grid-template-columns: ${props => props.isTablet ? '1fr' : 'repeat(auto-fill, 300px)'};
	grid-auto-rows: ${props => props.isTablet ? '' : '425px'};
	gap: 2vw;
	overflow: hidden;
	transition: max-height 2s ease-in-out, grid-gap 0.7s ease-in-out;
`

export const Card = styled.div`
	width: ${props => props.isTablet ? '100%' : '300px'};
	border: 3px solid var(--black);
	padding: 25px 30px 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	transition: 0.7s ease-in-out;

	&:hover {
		background: var(--black);
		& > a, h3, p {
			color: white;
		}
	}

	& > h3 {
		margin: 0 0 20px 0;
		font-family: Arial-Black, Arial, sans-serif;
		font-size: 1rem;
	}

	& > p {
		flex-grow: 1;
		text-align: justify;
	}

	& > a {
		margin-top: 20px;
		border-bottom: 1px solid white;
	}

	& > a:hover {
		border-bottom: 1px solid var(--black);
	}
`

export const ListContainer = styled.div`
	max-height: ${props => props.isExpanded ? '4000px' : '300px'};
	padding-right: 50px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transition: max-height 2s ease-in-out, grid-gap 0.7s ease-in-out;

	& > p {
		margin-top: ${props => props.first ? '0' : '1rem'};
		margin-bottom: ${props => props.last ? '0' : '1rem'};
		padding-left: 50px;
		font-size: 0.80rem;
		line-height: 1.5rem;
		text-align: justify;
	}
`

export const ListItem = styled.a`
	margin-bottom: 10px;
	padding-left: 50px;
	font-size: 0.80rem;
	line-height: 1.5rem;
	text-align: justify;
`

export const Expander = styled.p`
	margin-top: 35px;
	color: var(--accent);
	font-size: 0.9rem;
	cursor: pointer;
	transition: 1s ease-in-out;

	&:hover {
		color: var(--black);
	}
`

/* ---------------------------------------- RESUME LINK ---------------------------------------- */

export const ResumeLink = styled.div`
	display: flex;
	justify-content: flex-end;
  align-items: flex-end;

	& > a {
		color: var(--accent);
		font-size: 0.9rem;
		text-align: right;
    margin-right: 5px;
		transition: 1s ease-in-out;
	}
  
	& > a:hover {
    color: var(--black);
	}
  
	& span {
    font-size: 1.5em;
	}

  & > .inactive {
    color: var(--accent);
		font-size: 0.9rem;
		text-align: right;
    margin-right: 5px;
  }

  & > .inactive:hover {
    cursor: not-allowed
  }
`

/* ---------------------------------------- BOTTOM TEXT ---------------------------------------- */

export const BottomTextContainer = styled.div`
	padding: ${props => props.isMobile ? '3.5vh 3vh' : '3.5vh 7vh'};
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	& > div > a {
		transition: 1s ease-in-out;
		font-size:  ${props => props.isTablet ? '0.70rem' : ''};
	}

	& > div > a:hover {
		color: var(--accent);
	}

	& > p {
		margin-bottom: 5px;
		font-size:  ${props => props.isTablet ? '0.75rem' : ''};
	}
`