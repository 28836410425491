import React, { useEffect, useState, useRef } from 'react';
import GA from 'react-ga';
import ColorBlock from './components/ColorBlock';
import BackgroundImagery from './components/BackgroundImagery';
import Navigation from './components/Navigation';
import Skills from './components/Skillsets';
import Bio from './components/Bio';
import Projects from './components/Projects';
import BottomText from './components/BottomText';
// import { resumeLink } from '../data/documentLinks';
import { Page, SideText, Content, ProjectsContainer, ResumeLink, RowContainer } from '../aesthetic/styledComponents';

const BrowserView = () => {

  /* ---------------------------------------- GOOGLE ANALYTICS ---------------------------------------- */

  useEffect(() => {
    GA.initialize('UA-204784335-1');
    GA.pageview(window.location.pathname);
  }, []);

  /* ---------------------------------------- RESPONSE TO WINDOW SIZE ---------------------------------------- */

  const [viewWidth, setViewWidth] = useState(window.outerWidth);
  const [viewHeight, setViewHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener('keyup', () => setViewWidth(window.outerWidth));
    window.addEventListener('resize', () => setViewWidth(window.outerWidth));
    window.addEventListener('resize', () => setViewHeight(window.innerHeight));

    return () => {
      window.removeEventListener('keyup', () => setViewWidth(window.outerWidth));
      window.removeEventListener('resize', () => setViewWidth(window.outerWidth));
      window.removeEventListener('resize', () => setViewHeight(window.innerHeight));
    }
  }, []);

  /* ---------------------------------------- SCROLL POSITION ---------------------------------------- */

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', () => { setScrollPosition(window.pageYOffset) });

    return () => {
      window.removeEventListener('scroll', () => { setScrollPosition(window.pageYOffset) });
    }
  }, []);

  /* ---------------------------------------- SCROLL NAVIGATION ---------------------------------------- */

  const scrollToRef = (ref) => {
    if (scrollPosition < (viewHeight * 0.93)) window.scrollTo(0, ref.current.offsetTop - 50);
    else window.scrollTo(0, ref.current.offsetTop);
  };

  const skillsetRef = useRef(null);
  const scrollToSkillset = () => scrollToRef(skillsetRef);

  const pastProjectsRef = useRef(null);
  const scrollToPastProjects = () => scrollToRef(pastProjectsRef);

  const currentEndeavorsRef = useRef(null);
  const scrollToCurrentEndeavors = () => scrollToRef(currentEndeavorsRef);

  return (
    <Page>
      <RowContainer>
        <SideText isLeft>
          <p>
            CONNECT W/ ME ON &nbsp;
            <GA.OutboundLink eventLabel="linkedin" to="https://www.linkedin.com/in/baites/" target="_blank">
              LINKEDIN
            </GA.OutboundLink>
          </p>
        </SideText>

        <Content>
          <BackgroundImagery scrollPosition={scrollPosition} />

          <ColorBlock scrollPosition={scrollPosition} scrollToPastProjects={scrollToPastProjects} />

          {/* {!isTablet && */}
          <Navigation
            scrollPosition={scrollPosition}
            scrollToSkillset={scrollToSkillset}
            scrollToPastProjects={scrollToPastProjects}
            scrollToCurrentEndeavors={scrollToCurrentEndeavors}
            viewHeight={viewHeight}
            viewWidth={viewWidth}
          />
          {/* } */}

          <div ref={skillsetRef} />
          <Skills scrollPosition={scrollPosition} />

          <Bio />

          <ProjectsContainer>
            <div ref={pastProjectsRef} />
            <Projects isPast />

            <div ref={currentEndeavorsRef} />
            <Projects isCurrent />

            <ResumeLink>
              {/* <GA.OutboundLink eventLabel="resume" to={resumeLink} target="_blank" className='inactive'>
                VIEW RESUME <span>⟶</span>
              </GA.OutboundLink> */}
              <p className='inactive'>VIEW RESUME <span>⟶</span></p>
            </ResumeLink>
          </ProjectsContainer>
        </Content>

        <SideText isRight>
          <p>
            FOLLOW ME ON &nbsp;
            <GA.OutboundLink eventLabel="github" to="http://github.com/H-b8" target="_blank">
              GITHUB
            </GA.OutboundLink>
          </p>
        </SideText>
      </RowContainer>

      <BottomText />
    </Page>
  );
}

export default BrowserView;